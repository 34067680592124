import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const FormsMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "FormFormNavigationTitle",
        path: "/admin/forms",
        page: "Forms",
        subpage: "Forms",
      },
      {
        id: "FormQuestionNavigationTitle",
        path: "/admin/form_questions",
        page: "Forms",
        subpage: "FormQuestions",
      },
    ]}
  />
);

export default FormsMenu;

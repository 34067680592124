import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  TablePaginationConfig,
} from "antd";
import { Layout } from "layout";
import { FormsClient, FormDto, FormBriefDto, FormType } from "@api";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import FormsMenu from "@components/Admin/Forms/FormsMenu";
import { RcFile } from "antd/lib/upload";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(SECTION.Admin, "Forms", "Forms");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const formsClient = new FormsClient();

  const [state, setState] = useState({
    items: [] as FormDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );
  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "Forms")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await formsClient.getFormsWithPagination(
      _keywords ?? keywords,
      params.pagination.current,
      params.pagination.pageSize,
      capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
      (params.sortOrder ?? defaultSortOrder) == "ascend"
    );
    setState({
      ...state,
      items: (res.items as FormDto[]) || ([] as FormDto[]),
      pagination: {
        ...params.pagination,
        total: res.totalCount,
      },
      loading: false,
    });
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleDownloadDescriptions() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await formsClient.exportDescription();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadDescriptions(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await formsClient.uploadDescription({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadForms() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await formsClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadForms(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await formsClient.upload({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  function handleDeleteForm(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticDeletingMessage" />
        );
        try {
          await formsClient.delete(id);
          messageApi.success(
            <FormattedMessage id="StaticDeleteSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   defaultSortOrder,
    // },
    {
      // title: "Question Category",
      title: <FormattedMessage id="FormTableHeaderQuestionCategory" />,
      dataIndex: "questionCategoryAlias",
      key: "questionCategoryAlias",
      sorter: true,
    },
    {
      // title: "Form Name",
      title: <FormattedMessage id="FormTableHeaderFormName" />,
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      // title: "Shuffle",
      title: <FormattedMessage id="FormTableHeaderShuffle" />,
      dataIndex: "isShuffle",
      key: "isShuffle",
      sorter: true,
      render: (text, record: FormBriefDto) => record.isShuffle?.toString(),
    },
    {
      // title: "Type",
      title: <FormattedMessage id="FormTableHeaderType" />,
      dataIndex: "type",
      key: "type",
      sorter: true,
      render: (type: FormType) => <>{FormType[type]}</>,
    },
    {
      // title: "Rating Scale Title",
      title: <FormattedMessage id="FormTableHeaderRatingScaleTitle" />,
      dataIndex: "ratingScaleTitle",
      key: "ratingScaleTitle",
      sorter: true,
    },
    {
      // title: "Question Per Page",
      title: <FormattedMessage id="FormTableHeaderQuestionPerPage" />,
      dataIndex: "questionPerPage",
      key: "questionPerPage",
      sorter: true,
    },
    {
      // title: "Time added",
      title: <FormattedMessage id="FormTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="FormTableHeaderAction" />,
      key: "action",
      render: (text, record: FormBriefDto) =>
        record.id && auth.can(ACTION.Delete, "Forms") ? (
          <Button
            danger
            ghost
            onClick={() => handleDeleteForm(record.id as number)}
          >
            <FormattedMessage id="StaticDeleteButton" />
          </Button>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="FormFormNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <FormsMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Download, "FormDescriptions") ? (
                <Button type="primary" onClick={handleDownloadDescriptions}>
                  <FormattedMessage id="FormFormContentDownloadDescButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "FormDescriptions") ? (
                <Upload action={handleUploadDescriptions}>
                  <Button type="primary">
                    <FormattedMessage id="FormFormContentUploadDescButton" />
                  </Button>
                </Upload>
              ) : null}
              {auth.can(ACTION.Upload, "Forms") ? (
                <Button type="primary" onClick={handleDownloadForms}>
                  <FormattedMessage id="FormFormContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "Forms") ? (
                <Upload action={handleUploadForms}>
                  <Button type="primary">
                    <FormattedMessage id="FormFormContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
